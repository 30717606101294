* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    padding-right: 0 !important;
    font-family: 'Manrope', sans-serif;
    color: var(--base03);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
}

ol,
ul {
    padding-left: 0;
    margin-bottom: 0;
}

img {
    max-width: 100%;
}

p {
    margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


/* Colors Variables */
:root {
    --primary: linear-gradient(107.97deg, #BE49E7 -11.94%, #7650C8 100%);
    --white: #ffffff;
    --title: #924ED4;
    --subtitle: #666666;
    --p: #333333;
    --bggray: #FAFAFA;
    --box-shadow: 0px 3.36877px 20.2126px rgba(141, 141, 141, 0.15);
}

ul li {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    transition: .1s ease;
    cursor: pointer;
}

.form-control {
    
}

.form-control::placeholder {
    color: var(--form-placeholder);
}


.form-control:focus,
.form-control:focus-visible {
    background: var(--base-white) !important;
    border: 1px solid #363F72 !important;
    box-shadow: 0px 1px 2px rgb(62 71 132 / 5%), 0px 0px 0px 4px #eaecf5 !important;
}
/* btn primary  */
.btnprimary{
    background: var(--primary);
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 17.5px 48px;
    border: 1px solid var(--title) !important;
    color: var(--white);
    outline: none !important;
}
.btnprimary:hover{
    color: var(--white);
}
.btnunfill{
    background: var(--white);
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding: 17.5px 48px;
    border: 1px solid var(--title) !important;
    color: var(--title);
    outline: none !important;
}
.btnunfill:hover{
    background: var(--primary);
    color: var(--white);
}
.nav-link:focus, .nav-link:hover {
    color: var(--title);
    background: transparent;
    box-shadow: 0 0 0 2px rgb(146 78 212 / 40%);
}
/* btn white  */
.btnwhite {
    padding: 18px 40px;
    background: #ffffff;
    border-radius: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 128.5%;
    text-align: center;
    color: var(--title);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
}
.btnwhite:hover, .btnwhite:focus {
    box-shadow: 0px 0px 2px 4px var(--title);
    border: 1px solid #8643c8;
    color: var(--white);
    background: var(--title);
}
.btnwhite svg {
    margin-left: 5px;
    transition: .3s ease;
}
.btnwhite:hover svg {
    transform: rotate(45deg);
    margin-left: 10px;
}
/* Header css  */
.PrimaryHeader {
    position: relative;
    background: #ffffff;
    padding: 16px;
}



/* Banner css  */
.PrimaryHeader {
    position: relative;
    background: var(--white);
    padding: 16px;
}
.landing-banner {
    padding: 50px 0;
    background: var(--primary);
}
.landing-banner {
    position: relative;
    overflow: hidden;
}
.landing-banner::after, .landing-banner::before {
    content: '';
    opacity: 0.1;
    width: 484px;
    height: 484px;
    border-radius: 50%;
}
.landing-banner::after {
    background: #333333;
    opacity: .1;
    position: absolute;
    top: -160px;
    left: -160px;
}
.landing-banner::before {
    background: #ffffff;
    opacity: .1;
    position: absolute;
    bottom: -160px;
    right: -160px;
}
.landing-banner .row {
    display: flex;
    align-items: center;
}
.landing-banner .banner-content h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 104%;
    letter-spacing: -0.01em;
    color: var(--white);
    margin-bottom: 30px;
}
.landing-banner .banner-content p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 180%;
    color: var(--white);
    margin-bottom: 45px;
}
.landing-banner .banner-image {
    display: flex;
    justify-content: flex-end;
}
/* Logos slider css  */
.logos-wrapper {
    padding: 50px 0;
}
ul.Logos-slider {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
ul.Logos-slider li img {
    transition: .3s ease;
}
ul.Logos-slider li img:hover {
    filter: brightness(.1);
    cursor: pointer;
    transform: scale(1.01);
}
/* About Poe css  */
ul.Logos-slider li {
    margin: 0 20px;
}
.AboutPoe-wrapper {
    padding: 100px 0;
    position: relative;
}
.title h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    color: var(--title);
    margin-bottom: 80px;
}
.title h2.shaper {
    font-style: normal;
    font-weight: 700;
    font-size: 118.07px;
    line-height: 161px;
    color: var(--white);
    opacity: 0.08;
    -webkit-text-stroke: 2px #a24bdb;
    position: absolute;
    top: 13%;
    left: 13%;
    transform: translate(0px, 0px);
}
.title p {
    font-weight: 500;
    font-size: 20px;
    line-height: 180%;
    color: var(--p);
}
.AboutPoe-wrapper .about-img {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.HowWeWork-wrapper {
    padding: 70px 0;
    position: relative;
}
.HowWeWork-wrapper .HowWeWork-wrapperinner {
    padding: 50px;
    background: var(--bggray);
    border: 0.359375px solid var(--bggray);
    border-radius: 20px;
}
.titlecenterbar .title {
    text-align: center;
    position: relative;
}
.titlecenterbar .title h2.shaper {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    top: 50px;
}
.titlecenterbar .title h4 {
    margin-bottom: 90px;
}
.HowWeWork-boxes {
    position: relative;
}
.HowWeWork-boxes li {
    max-width: 380px;
    background: var(--white);
    border: 1.4375px solid #EBEBEB;
    box-shadow: 0px 3.36877px 20.2126px rgba(141, 141, 141, 0.15);
    border-radius: 16.8438px;
    padding: 30px;
}
.HowWeWork-boxes li .iconbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 16px;
}
.HowWeWork-boxes li .iconbox .iconbar {
    width: 66px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--primary);
    border-radius: 50%;
    font-size: 30px;
    color: var(--white);
    margin-right: 20px;
}
.HowWeWork-boxes li .iconbox h5 {
    margin: 0;
    font-size: 28.75px;
    line-height: 138.5%;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: var(--p);
    font-weight: 700;
}
.HowWeWork-boxes li .contentbar p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: var(--p);
}
.HowWeWork-boxes li:nth-child(2) {
    margin: 24px 0;
}
.HowWeWork-wrapper .row {
    display: flex;
    align-items: center;
}
/* testimonial css  */
.sliderbar {
    padding: 0 40px;
}
.sliderbar .user-review-slider {
    background: var(--white);
    border: 0.540237px solid #EBEBEB;
    border-radius: 8.56877px;
    padding: 30px;
    transition: .3s ease;
}
.sliderbar .user-review-slider:hover {
    box-shadow: 0px 85.6877px 68.5501px rgba(0, 0, 0, 0.01), 0px 55.5383px 40.1463px rgba(0, 0, 0, 0.01), 0px 33.0056px 21.8345px rgba(0, 0, 0, 0.008), 0px 17.1375px 11.1394px rgba(0, 0, 0, 0.01), 0px 6.98196px 5.58557px rgba(0, 0, 0, 0.01), 0px 1.58681px 2.69757px rgba(0, 0, 0, 0.002), 2.57063px 37.7026px 46.2713px rgba(0, 0, 0, 0.01);
}
.sliderbar .user-review-slider img {
    margin-bottom: 20px;
}
.sliderbar .user-review-slider p {
    font-style: normal;
    font-weight: 400;
    font-size: 14.4063px;
    line-height: 24px;
    color: var(--p);
}
.sliderbar .user-review-slider .userdetail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}
.sliderbar .user-review-slider .userdetail img {
    margin: 0 10px 0 0;
}
.sliderbar .user-review-slider .userdetail .username h4 {
    font-weight: 700;
    font-size: 13.71px;
    line-height: 19px;
    color: var(--p);
    margin: 0;
}
.sliderbar .user-review-slider .userdetail .username p {
    font-style: normal;
    font-weight: 400;
    font-size: 11.9963px;
    line-height: 16px;
    color: var(--subtitle);
}
/* Benefits css  */
.benefits-box {
    position: relative;
}
.benefits-box-wrapper .benefits-box {
    background: #F9F7FB;
    border-radius: 4px;
    padding: 40px 30px;
}
.benefits-box-wrapper {
    position: relative;
}
.benefits-box-wrapper .benefits-box svg {
    margin-bottom: 20px;
    font-size: 40px;
    color: var(--title);
}
.benefits-box-wrapper .benefits-box h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 21.375px;
    line-height: 32px;
    color: #282938;
}
.benefits-box-wrapper .benefits-box p {
    font-weight: 400;
    font-size: 14.25px;
    color: #282938;
    opacity: 0.7;
}
.beforefoot-wrapper {padding: 120px 0;text-align: center;}
.beforefoot-wrapper .title {
    margin-bottom: 30px;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
}
.beforefoot-wrapper .title h2 {
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -2px;
    color: var(--p);
    margin-bottom: 16px;
}
.beforefoot-wrapper .title p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: var(--subtitle);
}
.beforefoot-wrapper .title a.btnprimary {
    display: inline-block;
    width: 100%;
    margin-top: 30px;
}
.LandingFooter {
    padding: 20px 0;
    background: var(--primary);
}
.LandingFooter .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.LandingFooter .container ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.LandingFooter .container ul li {
    margin-left: 20px;
}
.LandingFooter .container ul li a {
    color: var(--white);
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    display: inline-block;
}
.loginbar {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginbar .formbar {
    position: relative;
    z-index: 99;
    padding: 30px;
    background: var(--white);
    border-radius: 20px;
    box-shadow: 0px 0px 40px 50px #00000014;
    text-align: center;
    max-width: 550px;
    width: 100%;
}
.loginbar .formbar img {
    margin-bottom: 30px;
}
.loginbar .formbar h2 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 30px;
}
.loginbar .formbar .PhoneInput {
    position: relative;
}
.loginbar .formbar .PhoneInput .PhoneInputCountry {
    width: 70px;
    height: 70px;
    border: 1px solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 0;
    border-radius: 8px 0px 0px 8px;
    margin: 0;
}
.loginbar .formbar p {
    font-size: 14px;
    text-align: left;
    margin-top: 5px;
}
.loginbar .formbar .PhoneInput input.PhoneInputInput {
    border: 1px solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 0;
    border-radius: 0px 8px 8px 0px;
    height: 70px;
    font-size: 30px;
    outline: none;
    padding-left: 10px;
}
.loginbar .formbar label.labelbar {
    display: block;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
}
.loginbar .formbar div {
    justify-content: center;
}
.loginbar .formbar div input[type="text"] {
    width: 70px !important;
    border: 1px solid #bfbfbf;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 70px;
    font-size: 30px;
    outline: none;
}
.loginbar .formbar div span {
    margin: 0 10px;
    font-size: 24px;
    color: #bfbfbf;
}
.loginbar .formbar .otpbar div {
    justify-content: center;
    margin-bottom: 20px;
}
.loginbar .formbar .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon img {
    border-radius: 4px;
    margin: 0 !important;
}
.loginbar .formbar .PhoneInput .PhoneInputCountry .PhoneInputCountryIcon {
    font-size: 26px;
    border-radius: 4px;
    box-shadow: none !important;
    margin: 0px !important;
}
.loginbar .formbar .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow {
    font-size: 26px;
}
.loginbar .formbar .PhoneInput .PhoneInputCountry div{
    margin: 0 0 0 8px !important;
}
.loginbar .formbar .btnbar {
    margin-top: 25px;
}
.loginbar .formbar .btnbar a {
    margin-top: 10px;
}
.loginbar .formbar .orbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}
.loginbar .formbar .orbar hr.or-line {
    width: 100%;
}
.loginbar .formbar .orbar .or-text {
    font-size: 20px;
    padding: 0 10px;
}
.loginbar .formbar .btnbar2 {
    display: flex;
    flex-direction: column;
}
.loginbar .formbar .btnbar2 a.btnprimary {
    margin-bottom: 10px;
}
.loginbar .formbar .PhoneInput .PhoneInputCountry .PhoneInputCountrySelectArrow {
    font-size: 26px;
}
.loginbar .formbar .btnbar2 svg {
    margin-right: 10px;
    font-size: 20px;
    filter: initial;
}