/* Responsive css  */

@media (max-width: 1199px){
    ul.Logos-slider li {
        margin: 0 20px;
    }
}

@media (max-width: 991px){
    .benefits-box-wrapper .benefits-box {
        margin-top: 30px;
    }
    .AboutPoe-wrapper {
        padding: 50px 0;
        position: relative;
    }
    .container, .container-md, .container-sm {
        max-width: 95%;
    }
    .landing-banner .banner-content h1 {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 10px;
    }
    .landing-banner .banner-content p {
        font-size: 18px;
        margin-bottom: 30px;
    }
    .logos-wrapper {
        padding: 20px 0;
    }
    ul.Logos-slider li {
        margin: 0 10px;
    }
    .AboutPoe-wrapper {
        padding: 50px 0;
    }
    .title h2.shaper {
        font-size: 90px;
        top: 0px;
    }
    .title h4 {
        margin-bottom: 30px;
    }
    .AboutPoe-wrapper .about-img {
        justify-content: center;
        margin-top: 30px;
    }
    .HowWeWork-wrapper .HowWeWork-wrapperinner .about-img {
        margin-top: 30px;
        text-align: center;
    }
    .HowWeWork-boxes li {
        max-width: 100%;
    }
}

@media (max-width: 767px){
    .title h4 {
        text-align: center;
        margin-bottom: 10px;
        font-size: 32px;
        line-height: 40px;
    }
    .HowWeWork-wrapper {
        padding: 30px 0;
        position: relative;
    }
    h2.shaper {
        display: none;
    }
    .title p {
        font-size: 16px;
        line-height: 26px;
    }
    .sliderbar .user-review-slider {
        margin-top: 30px;
    }
    .landing-banner .row {
        flex-direction: column-reverse;
    }
    .landing-banner .banner-image {
        margin-bottom: 30px;
        justify-content: center;
    }
    .landing-banner .banner-content h1, .landing-banner .banner-content p {
        text-align: center;
    }
    .banner-content {
        text-align: center;
    }
    .btnprimary {
        padding: 10px 15px;
        font-size: 14px;
    }
    .logos-wrapper {
        display: none;
    }
    .title h2.shaper {
        font-size: 60px;
        top: 0px;
    }
    .AboutPoe-wrapper {
        padding: 30px 0;
    }
    .HowWeWork-wrapper .HowWeWork-wrapperinner .about-img {
        margin-top: 30px;
        text-align: center;
    }
    .HowWeWork-boxes li {
        max-width: 100%;
    }
    .HowWeWork-wrapper .HowWeWork-wrapperinner {
        padding: 30px;
    }
    .titlecenterbar .title h4 {
        margin-bottom:10px;
    }
    .HowWeWork-boxes li .iconbox {
        flex-direction: column;
        margin: 0;
    }
    .HowWeWork-boxes li .iconbox .iconbar {
        margin: 0 0 10px 0;
    }
    .HowWeWork-boxes li .contentbar p {
        justify-content: center;
        text-align: center;
        font-size: 14px;
    }
    .beforefoot-wrapper {
        padding: 50px 0;
        text-align: center;
    }
    .beforefoot-wrapper .title h2 {
        font-size: 30px;
        line-height: 36px;
    }
    .title h4 {
        text-align: center;
        margin-bottom: 10px;
        font-size: 32px;
        line-height: 40px;
    }
    
    .beforefoot-wrapper .title h2 {
        font-size: 30px;
        line-height: 36px;
    }
    .LandingFooter .container {display: block;}
    .LandingFooter .container .title {
        text-align: center;
        margin-bottom: 30px;
    }
    .LandingFooter {
        padding: 40px 0;
    }
    .LandingFooter .container ul {
        display: block;
        text-align: center;
    }
    .LandingFooter .container ul li {
        margin: 15px 0 15px 0 !important;
    }
}